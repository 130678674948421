import React from 'react';
import Gallery from './gallery';
import Description from './description';
import Home from './home';
import ThailandTrip from './thailandtrip';
import PeruTrip from './perutrip';
import Navbar from './navbar';
import { textFieldClasses } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
    <h2 style={{textAlign: 'center'}}>THIS WEBSITE IS A WORK IN PROGRESS AND I WILL PUBLISH MORE INFORMATION ABOUT MY TRIPS ONCE I GET TIME TO ADD THINGS... FOR NOW, HERE ARE SOME PHOTOS FROM MY MOST RECENT TRIP IN THAILAND</h2>
    <h1 style={{textAlign: 'center'}}>Thailand Image Gallery</h1>
    <Gallery />
    </>
  );
}

export default App;
